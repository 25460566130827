import React from "react";
import { formatCurrency } from "../../utils/formatCurrency";

export const SalesSummary = ({sales}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
      <SummaryBox
        bgColor="#7913E5"
        title="Property price"
        amount={sales.property?.price}
        icon="ri-money-dollar-circle-line"
      />
        <SummaryBox
        bgColor="#0D60D8"
        title="Amount Paid"
        amount={sales.paid}
        icon="ri-money-dollar-circle-line"
      />
      <SummaryBox
        bgColor="#C9970E"
        title="Balance"
        amount={sales.outstanding}
        icon="ri-money-dollar-circle-line"
      />
    
      <SummaryBox
        bgColor="#808080"
        title="Installment period"
        duration={sales.duration}
        icon="ri-timer-line"
      />
      <SummaryBox
        bgColor="#222222"
        title="Transaction status"
        deal={sales.outstanding > 0 ? "Open" : "Close"}
        icon="ri-service-line"
      />
    </div>
  );
};

const SummaryBox = ({ bgColor, title, amount, duration, deal, icon }) => {
  
  return (
    <div className="homeCard" style={{ background: `${bgColor}` }}>
      <i className={`${icon} text-4xl`}></i>
      <div>
        <h5 className="font-medium pb-4 capitalize">{title}</h5>
        <span className="font-medium text-xl block">
          {duration && duration}
          {amount && formatCurrency(amount)}
          {deal && deal}
        </span>
      </div>
    </div>
  );
};
