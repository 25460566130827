import React from "react";
import { formatCurrency } from "../../utils/formatCurrency";

export const SPropertyDetails = ({ fullDetails }) => {
  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div>
          <img src={fullDetails.image} alt={fullDetails.title} className="rounded w-full" />
        </div>
        <div>
          <h3 className="font-semibold text-lg md:text-xl">
            {formatCurrency(fullDetails.price)}
          </h3>

          {fullDetails.title && (
            <h4 className="font-medium py-2">
              Land title:
              <span className="text-accent">{fullDetails.title}</span>
            </h4>
          )}

          <p className="text-sm text-justify">{fullDetails.description}</p>

          <div className="border-b mt-3" />
          <div className="flex mt-3 gap-7 ">
            <ul className="list-disc pl-4 text-sm">
              {fullDetails.moreDetails &&
                fullDetails.moreDetails.map((list) => {
                  return <li key={list}>{list}</li>;
                })}
            </ul>
            <div className="border-r" />

            {fullDetails.type === "Land" && (
              <div>
                <span className="text-sm">Plot size</span>
                <div className="flex items-center gap-3">
                  <i className="ri-landscape-line text-xl"></i>
                  <span className="text-sm">{fullDetails.details}</span>
                </div>
              </div>
            )}
          
            {fullDetails.type === "House" && (
              <div>
                <div className="flex items-center gap-5">
                  <div>
                    <span className="text-sm">Bedroom</span>
                    <div className="flex items-center gap-3">
                      <i className="ri-hotel-bed-line text-xl"></i>
                      <span>{fullDetails.details[0]}</span>
                    </div>
                  </div>
                  <div>
                    <span className="text-sm">Bathroom</span>
                    <div className="flex items-center gap-3">
                      <i className="fas fa-shower text-lg"></i>
                      <span>{fullDetails.details[1]}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
