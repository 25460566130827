import React, { useContext, useEffect, useState } from "react";
import { Modal } from "@mui/material";
import axios from "axios";
import { url } from "../../Api";
import { toast } from "react-toastify";
import { PageContext } from "../../Context/PageContextProvider";

const EditSalesRecord = ({ open, handleClose, id }) => {
  const { userInfo } = useContext(PageContext);
  const [cName, setCName] = useState("");
  const [cEmail, setCEmail] = useState("");
  const [cPhone, setCPhone] = useState("");
  const [sending, setSending] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    axios({
      url: `${url}/sales/${id}`,
      method: "patch",
      data : {
        buyerDetails: {
          buyerName: cName,
          buyerEmail: cEmail,
          buyerPhone: cPhone,
      },
      },
      headers: {
        authorization: `bearer ${userInfo?.token}`,
      },
    })
      .then((result) => {
        toast.success(result.data.message);
        setSending(false);
        handleClose();
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.data.message);
        setSending(false);
      });
  };

  useEffect(() => {
    axios
      .get(`${url}/sales/single/view/${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `bearer ${userInfo?.token}`,
        },
      })
      .then((response) => {
        let val = response.data.sales;
        setCName(val.buyerDetails.buyerName);
        setCEmail(val.buyerDetails.buyerEmail);
        setCPhone(val.buyerDetails.buyerPhone);
      })
      .catch((err) => {
      });
  }, [userInfo?.token, id]);

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="CModal scrollBar" style={{ maxWidth: 600 }}>
        <div className="flex justify-between items-center mb-7">
          <h5 className="font-semibold text-accent text-lg">
            Edit Sales Record
          </h5>
          <i
            className="fas fa-times cursor-pointer text-xl"
            onClick={handleClose}
          ></i>
        </div>

        <form onSubmit={onSubmit} className="grid grid-cols-1 lg:grid-cols-2 gap-x-4">
          <div className="form-control">
            <label>Client name</label>
            <input
              type="text"
              onChange={(e) => setCName(e.target.value)}
              required
              value={cName}
            />
          </div>
          <div className="form-control">
            <label>Client email</label>
            <input
              type="text"
              onChange={(e) => setCEmail(e.target.value)}
              required
              value={cEmail}
            />
          </div>
          <div className="form-control">
            <label>Client phone</label>
            <input
              type="text"
              onChange={(e) => setCPhone(e.target.value)}
              required
              value={cPhone}
            />
          </div>
          <div className="flex items-center mt-2">
            <button type="submit" className="button">
              {sending ? "Updating" : "Update"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditSalesRecord;

