import axios from "axios";
import { useEffect, useState } from "react";
import { url } from "../Api";

export const useFetchAllDataForSale = (userInfo) => {
  const [realtors, setRealtors] = useState([]);
  const [properties, setProperties] = useState([]);
  // === realtors ===//
  useEffect(() => {
    axios({
      url: `${url}/admin/realtors`,
      method: "GET",
      headers: {
        authorization: `bearer ${userInfo?.token}`,
      },
    }).then((response) => {
      let data = response.data.realtors;
      setRealtors(data);
    });

    // ==  fetch properties ===//
    axios({
      url: `${url}/properties`,
      method: "GET",
      headers: {
        authorization: `bearer ${userInfo?.token}`,
      },
    }).then((response) => {
      let data = response.data.properties;
      setProperties(data);
    });
  }, [userInfo]);

  return { realtors, properties };
};
