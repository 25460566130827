import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { url } from "../Api";
import { PageContext } from "../Context/PageContextProvider";
import PageToper from "./PageToper";
import avatar from "../Images/placeholderImage.jpeg";

export const DownLineDetails = ({ close, id }) => {
  const { userInfo } = useContext(PageContext);
  const [loading, setLoading] = useState(false);
  const [userAccount, setUserAccount] = useState({});
  const [switchGen, setSwitchGen] = useState(true);
  const [myDownlineFirstGen, setMyDownlineFirstGen] = useState([]);
  const [myDownlineSecondGen, setMyDownlineSecondGen] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${url}/user/profile/byID/${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `bearer ${userInfo?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setUserAccount(response.data.data.user);
        setMyDownlineFirstGen(response.data.data.firstlv);
        setMyDownlineSecondGen(response.data.data.secondlv);
      })
      .catch((err) => {});
  }, [userInfo?.token, id]);

  return (
    <div className="Container">
      <PageToper
        title="Downline"
        desc={`List of Realtors under ${userAccount.fullname}`}
        adminAccount
        closeEdit="closeEdit"
        close={close}
      />

      <div>
        <div className="flex items-center gap-4 mb-4">
          <button
            className={switchGen ? "button" : "transparentButton"}
            onClick={() => setSwitchGen(true)}
          >
            1st Generation
          </button>
          <button
            className={switchGen ? "transparentButton" : "button"}
            onClick={() => setSwitchGen(false)}
          >
            2nd Generation
          </button>
        </div>
        {loading && <h5 className="pt-4 font-medium text-lg">Loading...</h5>}
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-7">
          {switchGen ? (
            <>
              {!loading && myDownlineFirstGen.length === 0 ? (
                <span>No Downline Found</span>
              ) : (
                myDownlineFirstGen.map((info, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="rounded-md px-4 py-3 border  bg-gray-100 shadow-sm shadow-secondary hover:shadow-primary"
                      >
                        <div className="flex items-center gap-10 text-sm">
                          <div>
                            <div className="h-10 w-10">
                              <img
                                src={info.profile ? info.profile : avatar}
                                alt="avatar"
                                className="h-full w-full rounded-full object-cover object-top"
                              />
                            </div>
                          </div>
                          <div>
                            <h5 className="font-medium pb-1">
                              {info.fullname}
                            </h5>
                            <a href={`tel: ${info.phone}`}>{info.phone}</a>
                            <a
                              href={`mailto: ${info.email}`}
                              className="text-accent block hover:text-primary"
                            >
                              {info.email}
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              )}
            </>
          ) : (
            <>
              {myDownlineSecondGen.length === 0 ? (
                <span>No Downline Found</span>
              ) : (
                myDownlineSecondGen.map((info, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="rounded-md px-4 py-3 border  bg-gray-100 shadow-sm shadow-secondary hover:shadow-primary"
                      >
                        <div className="flex items-center gap-10 text-sm">
                          <div>
                            <div className="h-10 w-10">
                              <img
                                src={info.profile ? info.profile : avatar}
                                alt="avatar"
                                className="h-full w-full rounded-full object-cover object-top"
                              />
                            </div>
                          </div>
                          <div>
                            <h5 className="font-medium pb-1">
                              {info.fullname}
                            </h5>
                            <a href={`tel: ${info.phone}`}>{info.phone}</a>
                            <a
                              href={`mailto: ${info.email}`}
                              className="text-accent block hover:text-primary"
                            >
                              {info.email}
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
