import React, { useContext, useEffect, useState } from "react";
import { Modal } from "@mui/material";
import axios from "axios";
import { url } from "../../Api";
import { toast } from "react-toastify";
import { PageContext } from "../../Context/PageContextProvider";

export const UpdateCommission = ({ open, handleClose, historyId }) => {
  const { userInfo } = useContext(PageContext);
  const [commissionPaid, setCommissionPaid] = useState("");
  const [sending, setSending] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    axios({
      url: `${url}/sales/updatePaymentHistory/${historyId}`,
      method: "patch",
      data: {
        commissionPaid: commissionPaid === "true" ? true : false,
      },
      headers: {
        authorization: `bearer ${userInfo?.token}`,
      },
    })
      .then((result) => {
        toast.success("Updated Successfully");
        setSending(false);
        handleClose();
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.data.message);
        setSending(false);
      });
  };
  useEffect(async () => {
    await axios
      .get(`${url}/sales/getPaymentHistory/${historyId}`, {
        headers: {
          Accept: "application/json",
          Authorization: `bearer ${userInfo?.token}`,
        },
      })
      .then((response) => {
        setCommissionPaid(response?.data.sales.commissionPaid);
      })

      .catch((err) => {
        console.error(err);
      });
  }, [userInfo?.token, historyId]);
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="CModal scrollBar" style={{ maxWidth: 600 }}>
        <div className="flex justify-between items-center mb-7">
          <h5 className="font-semibold text-accent text-lg">
            Update Commission
          </h5>
          <i
            className="fas fa-times cursor-pointer text-xl"
            onClick={handleClose}
          ></i>
        </div>

        <form
          onSubmit={onSubmit}
          className="grid grid-cols-1 lg:grid-cols-2 gap-x-4"
        >
          <div className="form-control">
            <label>Commission</label>
            <select
              value={commissionPaid}
              onChange={(e) => setCommissionPaid(e.target.value)}
              required
            >
              <option value="" required>
                -select Commission status--
              </option>
              <option value="true">paid</option>
              <option value="false">unpaid</option>
            </select>
          </div>
          <div className="flex items-center mt-2">
            <button type="submit" className="button">
              {sending ? "Updating" : "Update"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
